<template>
  <v-container fluid class="pa-1" v-if="this.$store.state.user_info.role <= 1">
    <v-data-table :headers="headers" :items="items" class="elevation-1" dense>
      <template v-slot:item.actions="{ item }">
        <v-icon color="red" @click="dialogKick(item)"> mdi-close-box </v-icon>
      </template>
    </v-data-table>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5"
          >Are you sure you want to delete this item?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete = false"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" text @click="kick()">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    headers: [
      {
        text: "id",
        align: "start",
        sortable: false,
        value: "id",
      },
      {
        text: "username",
        align: "start",
        sortable: false,
        value: "email",
      },
      {
        text: "name",
        align: "start",
        sortable: false,
        value: "name",
      },
      {
        text: "token",
        align: "start",
        sortable: false,
        value: "token",
      },
      {
        text: "updated_at",
        align: "start",
        sortable: false,
        value: "updated_at",
      },
      {
        text: "created_at",
        align: "start",
        sortable: false,
        value: "created_at",
      },
      { text: "kick", value: "actions", sortable: false },
    ],
    items: [],
    deleteitem: null,
    dialogDelete: false,
  }),
  methods: {
    fetch() {
      this.items = [];
      this.axios
        .get(`${this.$store.state.apiurl}/api/usertoken`)
        .then((response) => {
          this.items = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    dialogKick(item) {
      this.deleteitem = item.id;
      this.dialogDelete = true;
    },
    kick() {
      //   console.log(item.id);
      this.axios
        .delete(
          `${this.$store.state.apiurl}/api/usertokenkick/${this.deleteitem}`,
          {}
        )
        .then(() => {
          this.dialogDelete = false;
          this.fetch();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>

<style>
</style>